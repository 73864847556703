<template>
  <!--begin::Page layout-->
  <div class="d-flex flex-column-fluid">
    <!--begin::Container-->
    <div class="container">
      <!--begin::row-image-stats-->
      <div class="row align-items-stretch">
        <!--begin::Background image-->
        <div class="col-8">
          <b-card
            class="card-custom gutter-b h-100"
            no-body
          >
            <div class="card-header flex-wrap border-0 pt-6 pb-0">
              <div class="card-title font-weight-bolder mt-0">
                <div class="card-label font-size-h5">
                  {{ trans('templates.edit.designer.backgroundImage.title') }}
                </div>
              </div>
              <!--placeholder: card-toolbar-->
            </div>
            <!--begin::card-body-->
            <div v-if="templateDataReady"
                 ref="designerbackgroundimage"
                 class="card-body pt-0"
                 :style="{ height: designerCardHeight > 0 ? `${ designerCardHeight }px` : 'auto' }"
            >
              <div class="symbol w-100 h-100">
                <!--begin::Profilepicture-->
                <UploadImage
                  :container-height="designerCardHeight"
                  :container-width="designerCardWidth"
                  db-collection="templates"
                  :doc-id="templateId"
                  db-path="designerbg"
                  :default-image="templateDesignerBg"
                  cloud-function="backendTemplatesDesignerUpdateImage"
                ></UploadImage>
                <!--end::Profilepicture-->
              </div>
            </div>
            <!--end::card-body-->
            <!--begin::card-body-loader-->
            <div v-else ref="designerbackgroundimage" class="card-body">
              <div class="d-flex h-100 align-items-center">
                <Loader
                  :text="trans('templates.edit.loading')"
                  loader-class="relative"
                ></Loader>
              </div>
            </div>
            <!--end::card-body-loader-->
          </b-card>
        </div>
        <!--end::Background image-->
        <!--begin::Designer-stats-->
        <div class="col-4">
          <div v-if="templateDataReady" class="card card-custom h-100">
            <!--begin::Header-->
            <div class="card-header border-0 pt-5">
              <div class="card-title font-weight-bolder mt-0">
                <div class="card-label font-size-h5">
                  {{ trans('templates.edit.info.countcard.title') }}
                </div>
              </div>
            </div>
            <!--end::Header-->
            <!--begin::Body-->
            <div class="card-body">
              <div class="d-flex align-items-center flex-column justify-content-center">
                <p class="font-size-h1 font-weight-bold text-primary mb-0">
                  {{ designerProductCount }}
                </p>
                <p class="text-dark-50">
                  {{ trans('templates.edit.info.countcard.totalCount') }}
                </p>
              </div>
            </div>
            <!--end::Body-->
          </div>
          <div v-else class="card-header border-0 pt-5">
            <div class="card-body">
              <Loader
                :text="trans('templates.edit.loading')"
                loader-class="relative"
              ></Loader>
            </div>
          </div>
        </div>
        <!--end::Designer-stats-->
      </div>
      <!--end::row-image-stats-->

      <!--begin::products-row-->
      <div class="row align-items-stretch pt-6">
        <!--begin::products-column-->
        <div class="col">
          <!--begin::ProductsList-->
          <b-card
            class="card-custom gutter-b"
            no-body
          >
            <div class="card-header flex-wrap border-0 pt-6 pb-0">
              <b-card-title>
                <h2 class="card-label">
                  {{ trans('templates.edit.designer.products.title') }}
                  <span class="d-block text-muted pt-2 font-size-sm">
                    {{ trans('templates.edit.designer.products.desc') }}
                  </span>
                </h2>
              </b-card-title>
              <!-- card-toolbar placeholder: add toolbar actions here :: <div class="card-toolbar">-->
            </div>
            <div class="card-body" style="min-height: 80px;">
              <!--begin::table-->
              <b-table
                id="template-table"
                primary-key="templateid"
                :fields="fields"
                :items="items"
                responsive="md"
              >
                <!--begin::product-image-->
                <template
                  v-if="tableProps.initializing"
                  v-slot:cell(image)
                  class="product-image"
                >
                  <div class="symbol symbol-50 symbol-sm flex-shrink-0">
                    <div class="symbol-label">
                      <div class="list-item loading rounded"></div>
                    </div>
                  </div>
                </template>

                <template
                  v-else
                  v-slot:cell(image)="data"
                  class="product-image"
                >
                  <div class="symbol symbol-60 symbol-sm flex-shrink-0">
                    <div class="symbol-label">
                      <img
                        v-if="data.item.image.type === 'img'"
                        class=""
                        :src="data.item.image.src"
                        alt="Product-image"
                      />
                      <div
                        v-else
                        class="svg-icon svg-icon-2x svg-icon-primary-light"
                      >
                        <inline-svg
                          :src="data.item.image.src"
                        ></inline-svg>
                      </div>
                    </div>
                  </div>
                </template>
                <!--end::product-image-->

                <!--begin::product-name-desc-->
                <template
                  v-if="tableProps.initializing"
                  v-slot:cell(name)
                  class="template-name table-vertical-center"
                >
                  <div class="list-item loading rounded"></div>
                </template>

                <template
                  v-else
                  v-slot:cell(name)="data"
                  class="template-name table-vertical-center"
                >
                  <span class="text-dark-75 font-weight-bold line-height-sm d-block pb-2">
                    {{ data.item.name }}
                  </span>
                  <span class="text-muted">
                    {{ data.item.desc }}
                  </span>
                </template>
                <!--end::product-name-desc-->

                <!--begin::product-layer-->
                <template
                  v-if="tableProps.initializing"
                  v-slot:cell(layer)
                  class="template-layer table-vertical-center"
                >
                  <div class="list-item loading rounded"></div>
                </template>

                <template
                  v-else
                  v-slot:cell(layer)="data"
                  class="template-layer table-vertical-center"
                >
                  <b-form @submit.prevent>
                    <b-form-input
                      :value="data.item.layer"
                      class="form-control-lg"
                      style="max-width: 80px;"
                      type="text"
                      trim
                      :placeholder="
                        data.item.layer !== null
                        ? data.item.layer.toString()
                        : trans('templates.edit.designer.products.layer.inputMissing')
                      "
                      @keyup="updateProductLayer(data.item.id, 'layer', $event.target.value)">
                    </b-form-input>
                  </b-form>
                </template>
                <!--end::product-layer-->

                <!--begin::product-render-->
                <template
                  v-if="tableProps.initializing"
                  v-slot:cell(render)
                  class="template-name table-vertical-center"
                >
                  <div class="list-item loading rounded"></div>
                </template>

                <template
                  v-else
                  v-slot:cell(render)="data"
                  class="template-name table-vertical-center"
                >
                  <div class="symbol symbol-80">
                    <!--begin::ProductRender-->
                    <UploadImage
                      :container-height="80"
                      :container-width="80"
                      db-collection="products"
                      :template-id="templateId"
                      :doc-id="data.item.id"
                      :db-path="`renders.${ templateId }`"
                      :default-image="data.item.render"
                      :text="trans('templates.edit.designer.products.table.render.missing')"
                      cloud-function="backendTemplatesDesignerUpdateProductRender"
                    ></UploadImage>
                    <!--end::ProductRender-->
                  </div>
                </template>
                <!--end::product-render-->

                <!--begin::product-overlay-->
                <template
                  v-if="tableProps.initializing"
                  v-slot:cell(overlay)
                  class="template-name table-vertical-center"
                >
                  <div class="list-item loading rounded"></div>
                </template>

                <template
                  v-else
                  v-slot:cell(overlay)="data"
                  class="template-name table-vertical-center"
                >
                  <div class="symbol symbol-80">
                    <!--begin::ProductRender-->
                    <UploadImage
                      :container-height="80"
                      :container-width="80"
                      db-collection="products"
                      :template-id="templateId"
                      :doc-id="data.item.id"
                      :db-path="`overlays.${ templateId }`"
                      :default-image="data.item.overlay"
                      :text="trans('templates.edit.designer.products.table.render.missing')"
                      cloud-function="backendTemplatesDesignerUpdateProductOverlay"
                    ></UploadImage>
                    <!--end::ProductRender-->
                  </div>
                  <b-form @submit.prevent>
                    <b-form-select
                      v-model="data.item.blendMode"
                      size="sm"
                      class="form-control-lg"
                      @change="updateProductBlendMode(data.item.id, data.item.blendMode, 500)
                    ">
                      <option :value="null">
                        Velg mode
                      </option>
                      <option v-for="(mode, idx) in blendModes" :key="`mode_${idx}`" :value="mode.key">
                        {{ mode.title }}
                      </option>
                    </b-form-select>
                  </b-form>
                </template>
                <!--end::product-overlay-->

                <!--begin::product-status-->
                <template
                  v-if="tableProps.initializing"
                  v-slot:cell(status)
                  class="template-name table-vertical-center"
                >
                  <div class="list-item loading rounded"></div>
                </template>

                <template
                  v-else
                  v-slot:cell(status)="data"
                  class="template-name table-vertical-center"
                >
                  <div>
                    <b-button
                      :variant="[!data.item.status ? 'warning' : 'success']"
                      @click="changeStatus(data.item.id)"
                    >
                      {{ capitalize(data.item.status
                        ? transChoice('global.active', 1)
                        : transChoice('global.inactive', 1))
                      }}
                    </b-button>
                  </div>
                </template>
                <!--end::product-status-->

                <!--begin::product-delete-->
                <template
                  v-if="tableProps.initializing"
                  v-slot:cell(delete)
                  class="template-name table-vertical-center"
                >
                  <div class="list-item loading rounded"></div>
                </template>

                <template
                  v-else
                  v-slot:cell(delete)="data"
                  class="template-name table-vertical-center"
                >
                <div class="text-center">
                  <b-button
                    :id="`popover-button-variant-${ data.item.id }`"
                    href="#"
                    tabindex="0"
                    variant="danger"
                  >
                    Slett
                  </b-button>
                  <b-popover :target="`popover-button-variant-${ data.item.id }`" variant="danger" triggers="focus">
                    <template #title>Slett produktet</template>
                    <p>Helt sikker?</p>
                    <b-button
                      variant="danger"
                      @click="deleteProduct(data.item.id)"
                    >
                      <p v-if="deleting" class="m-0 p-0">Sletter...</p>
                      <p v-else class="m-0 p-0">Bekreft!</p>
                    </b-button>
                  </b-popover>
                </div>
                </template>
                <!--end::product-delete-->
              </b-table>
              <!--end::table-->
              <!--begin::more-btn-->
              <div class="d-flex justify-content-center align-items-center flex-wrap">
                <div class="d-flex align-items-center py-3">
                  <div class="d-flex align-items-center">
                    <Loader
                      v-if="tableProps.initializing"
                      :text="trans('templates.index.table.btn.loading')"
                      loader-class="relative"
                      loader-style="margin-right: 80px;"
                    ></Loader>
                    <b-btn
                      v-else-if="tableProps.showMoreBtn"
                      ref="templateproducts_showmorebtn"
                      variant="primary"
                      class="py-4 px-6"
                      size="sm"
                      @click="loadMore(tableProps.lastVisibleDoc)"
                    >
                      {{ trans('templates.edit.designer.products.btn.default') }}
                    </b-btn>
                  </div>
                </div>
              </div>
              <!--end::more-btn-->
            </div>
          </b-card>
          <!--end::ProductsList-->
        </div>
        <!--end::products-column-->
      </div>
      <!--end::products-row-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Page layout-->
</template>

<script>
  import { SETTINGS } from '@src/store/settings'
  import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
  import { trans, transChoice } from '@src/core/helpers/translate'
  import { computed, onBeforeMount, onMounted, ref } from '@vue/composition-api'
  import * as objectPath from 'object-path'
  import { capitalize, truncate } from '@src/core/helpers/textUtils'

  export default {
    name: 'TemplatesEditDesigner',
    components: {
      Loader: () => import('@src/components/content/Loader'),
      UploadImage: () => import('@src/components/forms/UploadImage')
    },
    setup (props, { root, refs }) {
      // Global variables
      const fb = require('@src/firebaseConfig') // eslint-disable-line global-require
      const templateDataReady = ref(false)
      const templateId = ref(root.$route.params.templateId)
      const designerProductCount = ref(0)
      const subMenuData = computed(() => root.$store.getters[SETTINGS.GETTERS.GET_SUBMENU_DATA])
      const subMenuName = computed(() => root.$store.getters[SETTINGS.GETTERS.GET_ACTIVE_SUBMENU])
      const designerCardHeight = ref(0)
      const designerCardWidth = ref(0)

      const deleting = ref(false)

      const timers = []

      // Template related variables
      const templateName = ref('')
      const templateDesignerBg = ref('')

      const newSubMenuData = JSON.parse(JSON.stringify(subMenuData.value))
      objectPath.set(newSubMenuData, 'template.id', templateId.value)

      onBeforeMount(() => {
        if (subMenuName.value !== 'SubHeaderTemplatesEdit') {
          objectPath.del(newSubMenuData, 'template.name') // Reset the templatemenu
        }
        root.$store.dispatch(SETTINGS.ACTIONS.SET_ACTIVE_SUBMENU, 'TemplatesEdit')
        root.$store.dispatch(SETTINGS.ACTIONS.SET_SUBMENU_DATA, newSubMenuData)
      })

      const breadCrumbTitle = computed(() => {
        if (templateName.value === '') return capitalize(transChoice('global.loading', 2))
        return templateName.value
      })

      const setBreadCrumbs = () => {
        if (
          !objectPath.get(subMenuData, 'value.template.name', false) ||
          (templateName.value !== '' && objectPath.get(subMenuData, 'value.template.name', '') !== templateName.value) // eslint-disable-line no-extra-parens, max-len
        ) {
          newSubMenuData.template.name = templateName.value
          root.$store.dispatch(SETTINGS.ACTIONS.SET_SUBMENU_DATA, newSubMenuData)
          root.$store.dispatch(SET_BREADCRUMB, [
            {
              title: trans('nav.aside.templates.title'),
              route: '/templates'
            },
            {
              title: trans('nav.aside.templates.all'),
              route: '/templates'
            },
            {
              title: capitalize(transChoice('global.edit', 1))
            },
            {
              title: breadCrumbTitle.value
            }
          ])
        }
      }

      onMounted(() => {
        setBreadCrumbs()
      })

      const initTemplateData = async () => {
        const getTemplate = fb.functions.httpsCallable('backendTemplatesGetTemplateById')

        return await getTemplate({
          id: templateId.value
        }).then((res) => {
          // Templatepreview image
          let designerBgUrl = null
          const designerBgImages = objectPath.get(res, 'data.result.template.designerbg', {})
          const currentImageKey = !isNaN(parseInt(Object.keys(designerBgImages).pop(), 10)) ? parseInt(Object.keys(designerBgImages).pop(), 10) : false // eslint-disable-line max-len

          // Calculate the aspect ratio of the background-image container
          let imgW = refs.designerbackgroundimage !== undefined ? Math.round(refs.designerbackgroundimage.clientWidth) : 0 // eslint-disable-line max-len
          const styles = window.getComputedStyle(refs.designerbackgroundimage, null)
          if (styles !== undefined) imgW -= parseFloat(styles.paddingLeft) + parseFloat(styles.paddingRight)

          const imgH = Math.round(imgW / (16 / 9))
          if (imgW > 0) designerCardWidth.value = imgW
          if (imgW > 0 && imgH > 0) designerCardHeight.value = imgH

          // Fetch the most recent background-image
          if (currentImageKey !== false) {
            const imgPath = objectPath.get(res, `data.result.template.designerbg.${ currentImageKey }.path`, null)
            const imgFile = objectPath.get(res, `data.result.template.designerbg.${ currentImageKey }.file`, null)

            if (imgPath && imgFile) {
              const imgixParams = imgW > 0 ? `?fit=crop&w=${ imgW }&h=${ imgH }` : '?fit=crop&w=200&h=200'
              designerBgUrl = `${process.env.VUE_APP_IMAGE_CDN_URL}/${ imgPath }/${ imgFile }${ imgixParams }`
            }
          }

          templateDesignerBg.value = designerBgUrl

          // Additional variables
          templateName.value = objectPath.get(res, 'data.result.template.name', trans('templates.edit.info.missingName')) // eslint-disable-line max-len

          // Template data is ready
          templateDataReady.value = true
          setBreadCrumbs()
        }).catch((err) => {
          console.log('An error occurred while fetching the template:', err)
        })
      }

      const tableProps = ref({
        limit: 100,
        initializing: true,
        orderBy: 'name',
        sortOrder: 'asc',
        lastVisibleDoc: true,
        showMoreBtn: true
      })

      const tableItems = ref(new Array(tableProps.value.limit).fill({ }))

      const blendModes = ref([
        {
          key: 'color',
          title: 'color'
        },
        {
          key: 'color-burn',
          title: 'burn'
        },
        {
          key: 'color-dodge',
          title: 'dodge'
        },
        {
          key: 'darken',
          title: 'darken'
        },
        {
          key: 'difference',
          title: 'difference'
        },
        {
          key: 'exclusion',
          title: 'exclusion'
        },
        {
          key: 'hard-light',
          title: 'hardlight'
        },
        {
          key: 'hue',
          title: 'hue'
        },
        {
          key: 'lighten',
          title: 'lighten'
        },
        {
          key: 'luminosity',
          title: 'luminosity'
        },
        {
          key: 'multiply',
          title: 'multiply'
        },
        {
          key: 'overlay',
          title: 'overlay'
        },
        {
          key: 'saturation',
          title: 'saturation'
        },
        {
          key: 'screen',
          title: 'screen'
        },
        {
          key: 'soft-light',
          title: 'softlight'
        }
      ])

      const items = computed(() => tableItems.value.map((product) => {
        // ID
        const id = objectPath.get(product, 'id', null)

        // Product image
        const productImages = objectPath.get(product, 'images', {})
        let productImageKey = null

        Object.keys(productImages).some((pKey) => {
          if (productImages[pKey].deleted === undefined || productImages[pKey].deleted === false) {
            productImageKey = pKey
            return true
          }
          return false
        })

        const image = productImageKey !== null
          ? { type: 'img', src: `${process.env.VUE_APP_IMAGE_CDN_URL}/${ productImages[productImageKey].path }/${ productImages[productImageKey].file }?fit=crop&w=60&h=60` } // eslint-disable-line max-len, object-property-newline
          : { type: 'svg', src: '/media/svg/icons/Design/Image.svg' } // eslint-disable-line object-property-newline

        // Product layer
        const layer = objectPath.get(product, `layer.${templateId.value}`, null)

        // Product render
        let render = ''
        const renderImages = objectPath.get(product, `renders.${ templateId.value }`, {})
        const activeRenderImageKey = !isNaN(parseInt(Object.keys(renderImages).pop(), 10)) ? parseInt(Object.keys(renderImages).pop(), 10) : false // eslint-disable-line max-len

        if (activeRenderImageKey !== false) {
          const imgPath = objectPath.get(renderImages, `${ activeRenderImageKey }.path`, null)
          const imgFile = objectPath.get(renderImages, `${ activeRenderImageKey }.file`, null)

          if (imgPath && imgFile) {
            render = `${process.env.VUE_APP_IMAGE_CDN_URL}/${ imgPath }/${ imgFile }?fit=crop&w=80&h=80`
          }
        }

        // Product overlay
        let overlay = ''
        let blendMode = null
        const overlayImages = objectPath.get(product, `overlays.${ templateId.value }`, {})
        const activeOverlayImageKey = !isNaN(parseInt(Object.keys(overlayImages).pop(), 10)) ? parseInt(Object.keys(overlayImages).pop(), 10) : false // eslint-disable-line max-len

        if (activeOverlayImageKey !== false) {
          const imgPath = objectPath.get(overlayImages, `${ activeOverlayImageKey }.path`, null)
          const imgFile = objectPath.get(overlayImages, `${ activeOverlayImageKey }.file`, null)

          if (imgPath && imgFile) {
            overlay = `${process.env.VUE_APP_IMAGE_CDN_URL}/${ imgPath }/${ imgFile }?fit=crop&w=80&h=80`
            blendMode = objectPath.get(overlayImages, `${ activeOverlayImageKey }.blendMode`, null)
          }
        }

        // Additional data
        const name = truncate(objectPath.get(product, 'name', `(${ trans('templates.edit.designer.products.table.name.missing') })`), 60) // eslint-disable-line max-len
        const desc = truncate(objectPath.get(product, 'description', `(${ trans('templates.edit.designer.products.table.desc.missing') })`), 60) // eslint-disable-line max-len
        const status = objectPath.get(product, `templatesCategoryNames.${ templateId.value }.active`, false)

        return {
          id,
          blendMode,
          image,
          render,
          overlay,
          name,
          desc,
          status,
          layer
        }
      }))

      const fields = computed(() => [
        {
          key: 'image',
          label: capitalize(transChoice('global.image', 0)),
          sortable: false,
          tdClass: 'template-image align-middle',
          thClass: 'template-image-head'
        },
        {
          key: 'name',
          label: capitalize(transChoice('global.name', 0)),
          sortable: false,
          tdClass: 'template-name align-middle',
          thClass: 'template-name-head'
        },
        {
          key: 'layer',
          label: capitalize(transChoice('global.layer', 0)),
          sortable: false,
          tdClass: 'template-layer align-middle',
          thClass: 'template-layer-head'
        },
        {
          key: 'render',
          label: capitalize(transChoice('global.render', 0)),
          sortable: false,
          tdClass: 'template-render align-middle',
          thClass: 'template-render-head'
        },
        {
          key: 'overlay',
          label: 'Overlay',
          sortable: false,
          tdClass: 'template-render align-middle',
          thClass: 'template-render-head'
        },
        {
          key: 'status',
          label: capitalize(transChoice('global.status', 0)),
          sortable: false,
          tdClass: 'template-actions align-middle',
          thClass: 'template-actions-head'
        },
        {
          key: 'delete',
          label: capitalize(transChoice('global.delete', 0)),
          sortable: false,
          tdClass: 'template-actions align-middle',
          thClass: 'template-actions-head'
        }
      ])

      const loadMore = async (paginateDoc) => {
        const btn = refs.templateproducts_showmorebtn
        if (btn !== undefined) btn.classList.add('spinner', 'spinner-light', 'spinner-right', 'pr-14')

        const getProducts = fb.functions.httpsCallable('backendProductsGetProductsByTemplateIds')

        return await getProducts({
          limit: 1000,
          orderBy: 'name',
          sort: 'asc',
          data: {
            templateIds: [templateId.value]
          },
          paginateDoc
        }).then((res) => {
          if (objectPath.get(res, 'data.errors', false)) {
            tableProps.value.initializing = false
            tableItems.value = []
            console.log('ERRORS:', res.data.errors)
            return
          }

          designerProductCount.value = Object.keys(objectPath.get(res, 'data.result', {})).length

          if (tableProps.value.initializing) tableItems.value = [] // Remove placeholders
          tableProps.value.showMoreBtn = Object.keys(objectPath.get(res, 'data.result', {})).length >= tableProps.value.limit // eslint-disable-line max-len

          for (const docKey in objectPath.get(res, 'data.result', {})) {
            if (res.data.result[docKey] !== undefined) {
              const docData = res.data.result[docKey]
              tableItems.value.push(docData)
            }
          }

          if (btn !== undefined) btn.classList.remove('spinner', 'spinner-light', 'spinner-right', 'pr-14')
          tableProps.value.lastVisibleDoc = objectPath.get(res, 'data.paginateDoc.lastVisibleDoc', true)
        })
      }

      // Vue 2 "created" Vue trigger:
      initTemplateData()
      loadMore(tableProps.value.lastVisibleDoc).then(() => {
        tableProps.value.initializing = false
      })

      const inputTimer = (productId, timerDelay) => new Promise((resolve) => {
        if (timers[productId]) {
          clearTimeout(timers[productId])
          timers[productId] = null
        }

        timers[productId] = setTimeout(() => {
          resolve()
        }, timerDelay)
      })

      const changeStatus = async (productId) => {
        const updateProductStatus = fb.functions.httpsCallable('backendProductsUpdateStatus')

        await updateProductStatus({
          productId,
          templateId: templateId.value
        }).then((res) => {
          console.log('Completed the product update:', res)
          const errors = objectPath.get(res, 'data.errors', false)
          if (errors !== false) {
            root.$bvToast.toast(trans('templates.edit.designer.products.layer.update.error.msg', { error: errors[Object.keys(errors)[0]] }), { // eslint-disable-line max-len
              title: trans('templates.edit.designer.products.layer.update.error.title'),
              variant: 'danger',
              solid: true,
              autoHideDelay: 5000
            })
          } else {
            root.$bvToast.toast(trans('templates.edit.designer.products.layer.update.success.msg'), {
              title: trans('templates.edit.designer.products.layer.update.success.title'),
              variant: 'success',
              solid: true,
              autoHideDelay: 5000
            })
            tableProps.value.initializing = true
            tableItems.value = new Array(tableProps.value.limit).fill({ })
            loadMore().then(() => {
              tableProps.value.initializing = false
            })
          }
        }).catch((err) => {
          console.log('FAILED: the product was NOT updated', err)
        })
        console.log('Done!!')

        return true
      }

      const deleteProduct = async (productId) => { // eslint-disable-line max-params, max-len
        deleting.value = true
        const deleteProductFunc = fb.functions.httpsCallable('backendProductsDeleteProduct')

        await deleteProductFunc({
          productId,
          templateId: templateId.value
        }).then((res) => {
          console.log('Completed the product update:', res)
          const errors = objectPath.get(res, 'data.errors', false)
          if (errors !== false) {
            root.$bvToast.toast(trans('templates.edit.designer.products.layer.update.error.msg', { error: errors[Object.keys(errors)[0]] }), { // eslint-disable-line max-len
              title: trans('templates.edit.designer.products.layer.update.error.title'),
              variant: 'danger',
              solid: true,
              autoHideDelay: 5000
            })
            deleting.value = false
          } else {
            root.$bvToast.toast(trans('templates.edit.designer.products.layer.update.success.msg'), {
              title: trans('templates.edit.designer.products.layer.update.success.title'),
              variant: 'success',
              solid: true,
              autoHideDelay: 5000
            })
            deleting.value = false
            tableProps.value.initializing = true
            tableItems.value = new Array(tableProps.value.limit).fill({ })
            loadMore().then(() => {
              tableProps.value.initializing = false
            })
          }
        }).catch((err) => {
          console.log('FAILED: the product was NOT updated', err)
        })
        console.log('Done!!')

        return true
      }

      const updateProductBlendMode = async (productId, value, timerDelay = 1000) => { // eslint-disable-line max-params, max-len
        console.log('VALUE:', value)

        await inputTimer(productId, timerDelay).then(async () => {
          console.log('Save to DB...')
          const updateProduct = fb.functions.httpsCallable('backendProductsUpdateBlendMode')

          await updateProduct({
            productId,
            templateId: templateId.value,
            data: {
              blendMode: value
            }
          }).then((res) => {
            console.log('Completed the product update:', res)
            const errors = objectPath.get(res, 'data.errors', false)
            if (errors !== false) {
              root.$bvToast.toast(trans('templates.edit.designer.products.layer.update.error.msg', { error: errors[Object.keys(errors)[0]] }), { // eslint-disable-line max-len
                title: trans('templates.edit.designer.products.layer.update.error.title'),
                variant: 'danger',
                solid: true,
                autoHideDelay: 5000
              })
            } else {
              root.$bvToast.toast(trans('templates.edit.designer.products.layer.update.success.msg'), {
                title: trans('templates.edit.designer.products.layer.update.success.title'),
                variant: 'success',
                solid: true,
                autoHideDelay: 5000
              })
            }
          }).catch((err) => {
            console.log('FAILED: the product was NOT updated', err)
          })
        })

        console.log('Done!!')

        return true
      }

      const updateProductLayer = async (productId, field, value, timerDelay = 1000) => { // eslint-disable-line max-params, max-len
        console.log('VALUE:', value)
        const validInput = !isNaN(value)

        console.log('ValidInput:', validInput, timerDelay)

        await inputTimer(productId, timerDelay).then(async () => {
          console.log('Save to DB...')
          const updateProduct = fb.functions.httpsCallable('backendProductsUpdateLayer')

          await updateProduct({
            productId,
            templateId: templateId.value,
            data: {
              productLayer: value
            }
          }).then((res) => {
            console.log('Completed the product update:', res)
            const errors = objectPath.get(res, 'data.errors', false)
            if (errors !== false) {
              root.$bvToast.toast(trans('templates.edit.designer.products.layer.update.error.msg', { error: errors[Object.keys(errors)[0]] }), { // eslint-disable-line max-len
                title: trans('templates.edit.designer.products.layer.update.error.title'),
                variant: 'danger',
                solid: true,
                autoHideDelay: 5000
              })
            } else {
              root.$bvToast.toast(trans('templates.edit.designer.products.layer.update.success.msg'), {
                title: trans('templates.edit.designer.products.layer.update.success.title'),
                variant: 'success',
                solid: true,
                autoHideDelay: 5000
              })
            }
          }).catch((err) => {
            console.log('FAILED: the product was NOT updated', err)
          })
        })

        console.log('Done!!')

        return true
      }

      return {
        trans,
        transChoice,
        capitalize,
        templateDataReady,
        designerProductCount,
        designerCardHeight,
        designerCardWidth,
        // template related
        tableProps,
        templateId,
        templateDesignerBg,
        loadMore,
        updateProductLayer,
        updateProductBlendMode,
        blendModes,
        items,
        fields,
        deleteProduct,
        deleting,
        changeStatus
      }
    }
  }
</script>

<style lang="scss">

</style>
